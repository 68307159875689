import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';


import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { createTheme , ThemeProvider } from '@material-ui/core/styles';
import { LoadingWLogo } from './components/LoadingWLogo';
const App = React.lazy(() => import('./App'));

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


const theme = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});




ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingWLogo/>}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <App /> 
        </ThemeProvider>
      </StylesProvider>
    </Suspense>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
